import { $api } from "services/AxiosInstance";

export class FaqService {
  static getFaqs(data) {
    return $api.post("/api/admin/faq/all", data);
  }

  static getFaq(id) {
    return $api.get("/api/admin/faq", { params: { id } });
  }

  static createFaq(values) {
    return $api.post("/api/admin/faq", values);
  }

  static updateFaq(values) {
    return $api.put("/api/admin/faq", values);
  }

  static deleteFaq(id) {
    return $api.delete("/api/admin/faq", { params: { id } });
  }

  static createFaqSettings(values) {
    return $api.post("/api/admin/generalSettings/all/body/save", values);
  }
  static createFaqGeneralSettings(values) {
    return $api.post("/api/admin/faq/generalSettings/id", values);
  }
  static getFaqGeneralSettings(faqId) {
    return $api.post(`/api/admin/generalSettings/all/faqId`, { faqId });
  }
  static clearGeneralSettings(values) {
    return $api.post("/api/admin/faq/generalSettings/clear", values);
  }
}
