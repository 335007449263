import { $api } from "services/AxiosInstance";

export class PriceService {
  static getAllMobileOperators() {
    return $api.get("/api/admin/price/mobileOperator/all");
  }
  static getAllByMobileOperatorId(mobileOperatorId) {
    return $api.get(`/api/admin/price/all/mobileOperatorId?mobileOperatorId=${mobileOperatorId}`);
  }
  static getAllPrices(params) {
    return $api.post("/api/admin/price/all/proxyTypeId", params);
  }

  static getPrices(page, size) {
    return $api.post("/api/admin/price/all", { page, size });
  }

  static getPrice(id) {
    return $api.get("/api/admin/price", { params: { id } });
  }
  static deletePrice(id) {
    return $api.delete(`/api/admin/price?id=${id}`);
  }
  static deleteMobilePrice(id) {
    return $api.delete(`/api/admin/price/all?id=${id}`);
  }
  static updatePrices(params) {
    return $api.put("/api/admin/price/update/all", params);
  }

  static updatePrice(params) {
    return $api.put("/api/admin/price", params);
  }

  static createPrice(params) {
    return $api.post("/api/admin/price", params);
  }
}
