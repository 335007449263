import {$api} from "../AxiosInstance";

export class RedirectService {
    static getAllByPage(data) {
        return $api.post(`/api/admin/redirect/all`, data);
    }
    static getById(id) {
        return $api.get(`/api/admin/redirect?id=${id}`);
    }
    static create(payload) {
        return $api.post(`/api/admin/redirect`, payload);
    }
    static update(payload) {
        return $api.put(`/api/admin/redirect`, payload);
    }
    static deleteById(id) {
        return $api.delete(`/api/admin/redirect?id=${id}`);
    }
}
