import { $api } from "services/AxiosInstance";

export class RentPeriodService {
  static getAllByProxyTypeId(proxyTypeId) {
    return $api.get(`/api/admin/rentPeriod/all/proxyTypeId?proxyTypeId=${proxyTypeId}`);
  }
  static getAllRentPeriods() {
    return $api.get("/api/admin/rentPeriod/all");
  }

  static getRentPeriods(params) {
    return $api.post("/api/admin/rentPeriod/all/proxyTypeId", params);
  }

  static getRentPeriodsByProxy(id) {
    return $api.get(`/api/admin/rentPeriod/all/proxyTypeId?proxyTypeId=${id}`);
  }

  static getRentPeriod(id) {
    return $api.get("/api/admin/rentPeriod", { params: { id } });
  }

  static createRentPeriod(values) {
    return $api.post("/api/admin/rentPeriod", values);
  }

  static updateRentPeriod(values) {
    return $api.put("/api/admin/rentPeriod", values);
  }

  static deleteRentPeriod(id) {
    return $api.delete("/api/admin/rentPeriod", { params: { id } });
  }
}
