import { $api } from "../AxiosInstance";

export class SeoBlockService {
    static deleteSeoBlockById(id) {
        return $api.delete(`/api/admin/seoBlock?id=${id}`);

    }
    // Create
    static createSeoBlock(data) {
        return $api.post("/api/admin/seoBlock", data);
    }
    static createSeoBlockTranslation(data) {
        return $api.post("/api/admin/seoBlockTranslation", data);
    }
    // Get all
    static getAll() {
        return $api.get("/api/admin/seoBlock/all");
    }
    static getAllByPage(data) {
        return $api.post("/api/admin/seoBlock/all", data);
    }
    // Get by id
    static getSeoBlockById(id) {
        return $api.get(`/api/admin/seoBlock?id=${id}`);
    }
    static getSeoBlockTranslationById(seoBlockId) {
        return $api.get(`/api/admin/seoBlockTranslation/all/seoBlockId?seoBlockId=${seoBlockId}`);
    }
    // Update
    static updateSeoBlock(data) {
        return $api.put("/api/admin/seoBlock", data);
    }
    static updateSeoBlockTranslation(data) {
        return $api.put("/api/admin/seoBlockTranslation", data);
    }
}
