import { $api } from "services/AxiosInstance";

export class DateFormatTemplateService {
  static getAllDateFormatTemplates() {
    return $api.get("/api/admin/dateFormatTemplate/all");
  }

  static getDateFormatTemplates(params) {
    return $api.post("/api/admin/dateFormatTemplate/all", params);
  }

  static getDateFormatTemplate(id) {
    return $api.get("/api/admin/dateFormatTemplate", { params: { id } });
  }

  static createDateFormatTemplate(values) {
    return $api.post("/api/admin/dateFormatTemplate", values);
  }

  static updateDateFormatTemplate(values) {
    return $api.put("/api/admin/dateFormatTemplate", values);
  }

  static deleteDateFormatTemplate(id) {
    return $api.delete("/api/admin/dateFormatTemplate", { params: { id } });
  }
}
