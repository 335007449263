import { $api } from "services/AxiosInstance";

export class ProxyTypeService {
  static getProxyTypes() {
    return $api.get("/api/admin/proxyType/all");
  }

  static getProxyTypeById(id) {
    return $api.get("/api/admin/proxyType", { params: { id } });
  }

  static updateProxyType(params) {
    return $api.put("/api/admin/proxyType", params);
  }

  static saveProxyType(params) {
    return $api.post("/api/admin/proxyType", params);
  }

  static deleteProxyType(id) {
    return $api.delete("/api/admin/proxyType", { params: { id } });
  }
}