import { $api } from "services/AxiosInstance";

export class ProxyPackageService {
  static getAllProxyPackages() {
    return $api.get("/api/admin/proxyPackage/all");
  }

  static getProxyPackages(page, size) {
    return $api.post("/api/admin/proxyPackage/all", { page, size });
  }

  static getProxyPackage(id) {
    return $api.get("/api/admin/proxyPackage", { params: { id } });
  }

  static createProxyPackage(values) {
    return $api.post("/api/admin/proxyPackage", values);
  }

  static updateProxyPackage(values) {
    return $api.put("/api/admin/proxyPackage", values);
  }

  static deleteProxyPackage(id) {
    return $api.delete("/api/admin/proxyPackage", { params: { id } });
  }
}
