import { $api } from "services/AxiosInstance";

export class CountryService {
  static getAllCountries() {
    return $api.get("/api/admin/country/all");
  }

  static getCountries(params) {
    return $api.post("/api/admin/country/all", params);
  }

  static getCountry(id) {
    return $api.get("/api/admin/country", { params: { id } });
  }

  static createCountry(values) {
    return $api.post("/api/admin/country", values);
  }

  static updateCountry(values) {
    return $api.put("/api/admin/country", values);
  }

  static deleteCountry(id) {
    return $api.delete("/api/admin/country", { params: { id } });
  }
}
