import { $api } from "services/AxiosInstance";

export class PaymentSystemService {
  static changePaymentSystemsOrder(paymentSystemSortedIds) {
    return $api.put("/api/admin/paymentSystem/index", {
      paymentSystemSortedIds,
    });
  }
  static getAllPaymentSystems() {
    return $api.get("/api/admin/paymentSystem/all");
  }

  static getPaymentSystems(params) {
    return $api.post("/api/admin/paymentSystem/all", params);
  }

  static getPaymentSystem(id) {
    return $api.get("/api/admin/paymentSystem", { params: { id } });
  }

  static createPaymentSystem(values) {
    return $api.post("/api/admin/paymentSystem", values);
  }

  static updatePaymentSystem(values) {
    return $api.put("/api/admin/paymentSystem", values);
  }

  static deletePaymentSystem(id) {
    return $api.delete("/api/admin/paymentSystem", { params: { id } });
  }

  static getPaymentSystemTypes() {
    return $api.get("/api/admin/paymentSystem/types");
  }
}
