import axios from "axios";
import { AUTH_TOKEN, AUTH_USER } from "../redux/constants/Auth";

export const $api = axios.create();

$api.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response === undefined || error.response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_USER);

      if (error.config.url !== "/api/login") {
        window.location.href = "/";
      }
    }

    return Promise.reject(error);
  }
);
