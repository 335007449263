import { $api } from "../AxiosInstance";

export class OrdersService {
  static getOrders(params) {
    return $api.post("/api/admin/order/all", params);
  }

  static getOrdersExtend(params) {
    return $api.post("/api/admin/orderExtend/all", params);
  }

  static getOrderById(id) {
    return $api.get(`/api/admin/order?id=${id}`);
  }

  static getOrderExtendById(id) {
    return $api.get(`/api/admin/orderExtend?id=${id}`);
  }

  static getOrderExtendsById(params) {
    return $api.post("/api/admin/orderExtend/all/orderId", params);
  }

  static payOrder(id) {
    return $api.get(`/api/admin/order/payed?id=${id}`);
  }

  static updateOrderGoal(params) {
    return $api.put("/api/admin/order", params);
  }

  static updateOrderExtendIps(id) {
    return $api.get(`/api/admin/orderExtend/ips?id=${id}`);
  }

  static payOrderExtend(id) {
    return $api.get(`/api/admin/orderExtend/payed?id=${id}`);
  }
}