import { $api } from "services/AxiosInstance";

export class BlogService {
  static saveArticleId() {
    return $api.post("/api/admin/article/save");
  }

  static getArticles(params) {
    return $api.post("/api/admin/article/all", params);
  }

  static getArticle(articleId) {
    return $api.get("/api/admin/article/all/articleId", { params: { articleId } });
  }

  static updateArticle(params) {
    return $api.put("/api/admin/article", params);
  }

  static uploadImage(file, articleTranslationId) {
    return $api.post(`/api/admin/article/file?articleTranslationId=${articleTranslationId}`, file);
  }
  static deleteImage(fileId) {
    return $api.delete(`/api/admin/article/file?fileId=${fileId}`);
  }

  static deleteArticle(id) {
    return $api.delete("/api/admin/article", { params: { id } });
  }

  static saveArticle(params) {
    return $api.post("/api/admin/article", params);
  }

  static getArticleTranslation(params) {
    return $api.post("/api/admin/articleTranslation/get", params);
  }

  static updateArticleTranslation(params) {
    return $api.put("/api/admin/articleTranslation", params);
  }

  static saveArticleTranslation(params) {
    return $api.post("/api/admin/articleTranslation", params);
  }

  // static getTranslationOfArticleByTag(tag) {
  //   return $api.get("/api/admin/blog/all/tag", { params: { tag } });
  // }

  // static getArticlesByLocaleCode(values) {
  //   return $api.post("/api/admin/blog/all/localeCode", values);
  // }

  // static saveOrUpdateArticleTag(values) {
  //   return $api.post("/api/admin/blog/tag", values);
  // }

  // static getArticle(id) {
  //   return $api.get("/api/admin/blog", { params: { id } });
  // }

  // static createOrUpdateArticle(values) {
  //   return $api.put("/api/admin/blog", values);
  // }

  // static updateArticle(values) {
  //   return $api.put("/api/admin/blog", values);
  // }

  // static deleteArticleByTag(tag) {
  //   return $api.delete("/api/admin/blog/tag", { params: { tag } });
  // }
}
