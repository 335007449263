import {GET_LANGUAGE_ALL} from "../constants/Language";

const initState = {
    defaultCode: "en",
    all: {
        loading: false,
        errors: null,
        data: []
    }
}

const language = (state = initState, action) => {
    switch (action.type) {
        case GET_LANGUAGE_ALL.PENDING: {
            return {
                ...state,
                all: {
                    ...state.all,
                    loading: true
                }
            }
        }
        case GET_LANGUAGE_ALL.FULFILLED: {
            return {
                ...state,
                all: {
                    loading: false,
                    errors: initState.errors,
                    data: action.payload
                }
            }
        }
        case GET_LANGUAGE_ALL.REJECTED: {
            return {
                ...state,
                all: {
                    loading: false,
                    data: initState.data,
                    errors: action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default language
