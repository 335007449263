import { $api } from "services/AxiosInstance";

export class EmailTemplateService {
  static getAllEmailTemplates() {
    return $api.get("/api/admin/emailTemplate/all");
  }

  static getEmailTemplates(params) {
    return $api.post("/api/admin/emailTemplate/all", params);
  }

  static getEmailTemplate(id) {
    return $api.get("/api/admin/emailTemplate", { params: { id } });
  }

  static createEmailTemplate(values) {
    return $api.post("/api/admin/emailTemplate", values);
  }

  static updateEmailTemplate(values) {
    return $api.put("/api/admin/emailTemplate", values);
  }

  static deleteEmailTemplate(id) {
    return $api.delete("/api/admin/emailTemplate", { params: { id } });
  }

  static viewEmailTemplate(params) {
    return $api.post("/api/admin/emailTemplate/view", params);
  }
  static previewEmailTemplate(params) {
    return $api.post("/api/admin/emailTemplate/preview", params);
  }
}
