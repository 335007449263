import axios from "axios";

export class FilesService {

    static url = "/api/admin/files"
    static async getById(id) {
        return await axios.get(this.url + "/id", {params:{id}});
    }

    static async getAll() {
        return await axios.get(this.url + "/all");
    }

    static async create({name, content}) {
        return await axios.post(this.url + "/create", {name, content});
    }

    static async update({name, content}) {
        return await axios.put(this.url + "/update", {name, content});
    }

    static async deleteById(id) {
        return await axios.delete(this.url + "/deleteById", {params: {id}});
    }
}
