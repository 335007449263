import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {GET_LANGUAGE_ALL} from "../constants/Language";
import {LanguageService} from "../../services/admin";

export function* getLanguageAll() {
    yield takeEvery(GET_LANGUAGE_ALL.PENDING, function* () {
        try {
            const languages = yield call(LanguageService.getAll);

            if (languages.status !== 200) {
                throw new Error({message: `Error status code ${languages.status}`})
            }

            yield put({type: GET_LANGUAGE_ALL.FULFILLED, payload: languages.data});
        } catch (err) {
            yield put({type: GET_LANGUAGE_ALL.REJECTED, payload: [err?.message]});
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getLanguageAll),
    ]);
}
