import {$api} from "../AxiosInstance";

export class MobileCharacteristicService {
    static getAllUnique() {
        return $api.get(`/api/admin/mobile/characteristic/all`);
    }
    static deleteAllByTag(tag) {
        return $api.delete(`/api/admin/mobile/characteristic/all?tag=${tag}`);
    }
    static getAllByMobileOperatorId(mobileOperatorId) {
        return $api.get(`/api/admin/mobile/characteristic?mobileOperatorId=${mobileOperatorId}`);
    }

    /**
     *
     * @param {Object} data
     * @param {String} data.id
     * @param {String} data.mobileOperatorId
     * @param {String} data.type
     * @param {*} data.value
     * @param {Object} data.namesByLocale
     * @returns {Promise}
     */
    static saveCharacteristic(data) {
        return $api.post(`/api/admin/mobile/characteristic`, data);
    }

    static deleteCharacteristicById(characteristicId) {
        return $api.delete(`/api/admin/mobile/characteristic?id=${characteristicId}`);
    }

    // For edit all

    static addCharacteristicToAllMobileOperators(payload) {
        return $api.post(`/api/admin/mobile/characteristic/all`, payload);
    }
}
