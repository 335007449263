import { $api } from "services/AxiosInstance";

export class FaqGroupService {
  static getAllFaqGroups() {
    return $api.get("/api/admin/faqGroup/all");
  }

  static getFaqGroups(params) {
    return $api.post("/api/admin/faqGroup/all", params);
  }

  static getFaqGroup(id) {
    return $api.get("/api/admin/faqGroup", { params: { id } });
  }

  static createFaqGroup(values) {
    return $api.post("/api/admin/faqGroup", values);
  }

  static updateFaqGroup(values) {
    return $api.put("/api/admin/faqGroup", values);
  }

  static deleteFaqGroup(id) {
    return $api.delete("/api/admin/faqGroup", { params: { id } });
  }
}
