import { $api } from "services/AxiosInstance";

export class GoalService {
  static getAllGoals() {
    return $api.get("/api/admin/goal/all");
  }

  static getGoals(params) {
    return $api.post("/api/admin/goal/all", params);
  }

  static getGoal(id) {
    return $api.get("/api/admin/goal", { params: { id } });
  }

  static createGoal(values) {
    return $api.post("/api/admin/goal", values);
  }

  static updateGoal(values) {
    return $api.put("/api/admin/goal", values);
  }

  static deleteGoal(id) {
    return $api.delete("/api/admin/goal", {
      params: { id },
    });
  }
}
