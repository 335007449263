import { $api } from "services/AxiosInstance";

export class ReviewService {
  static getReviews(params) {
    return $api.post("/api/admin/review/all/localeCode", params);
  }

  static getReviewsByLocale() {
    return $api.get("/api/admin/review/all/groupByLocale"); //deleted
  }

  static getReview(id) {
    return $api.get("/api/admin/review", { params: { id } });
  }

  static createReview(values) {
    return $api.post("/api/admin/review", values);
  }

  static updateReview(values) {
    return $api.put("/api/admin/review", values);
  }

  static deleteReview(id) {
    return $api.delete("/api/admin/review", { params: { id } });
  }

  static createReviewWithImage(values) {
    return $api.post("/api/admin/review/image", values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static updateReviewWithImage(values) {
    return $api.put("/api/admin/review/image", values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
