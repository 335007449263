import { $api } from "services/AxiosInstance";

export class PromocodeService {
  static getAllPromocodes() {
    return $api.get("/api/admin/promoCode/all");
  }

  static getPromocodes(params) {
    return $api.post("/api/admin/promoCode/all/type", params);
  }

  static getPromocode(id) {
    return $api.get("/api/admin/promoCode", { params: { id } });
  }

  static createPromocode(values) {
    return $api.post("/api/admin/promoCode", values);
  }

  static updatePromocode(values) {
    return $api.put("/api/admin/promoCode", values);
  }

  static deletePromocode(id) {
    return $api.delete("/api/admin/promoCode", { params: { id } });
  }
}
