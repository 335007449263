import { $api } from "services/AxiosInstance";

export class GoalGroupService {
  static getAllGoalGroups() {
    return $api.get("/api/admin/goalGroup/all");
  }

  static getGoalGroups(params) {
    return $api.post("/api/admin/goalGroup/all", params);
  }

  static getGoalGroup(id) {
    return $api.get("/api/admin/goalGroup", { params: { id } });
  }

  static createGoalGroup(values) {
    return $api.post("/api/admin/goalGroup", values);
  }

  static updateGoalGroup(values) {
    return $api.put("/api/admin/goalGroup", values);
  }

  static deleteGoalGroup(id) {
    return $api.delete("/api/admin/goalGroup", { params: { id } });
  }
}
