import { $api } from "services/AxiosInstance";

export class CurrencyService {
  static getAllCurrencies() {
    return $api.get("/api/admin/currency/all");
  }

  static getCurrencies(params) {
    return $api.post("/api/admin/currency/all", params);
  }

  static getCurrency(id) {
    return $api.get("/api/admin/currency", { params: { id } });
  }

  static createCurrency(values) {
    return $api.post("/api/admin/currency", values);
  }

  static updateCurrency(values) {
    return $api.put("/api/admin/currency", values);
  }

  static deleteCurrency(id) {
    return $api.delete("/api/admin/currency", { params: { id } });
  }
}
