import { $api } from "services/AxiosInstance";

export class CurrencyRateService {
  static getAllCurrencyRates() {
    return $api.get("/api/admin/currencyRate/all");
  }

  static getCurrencyRates(params) {
    return $api.post("/api/admin/currencyRate/all", params);
  }

  static getCurrencyRate(id) {
    return $api.get("/api/admin/currencyRate", { params: { id } });
  }
}
