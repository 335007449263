import { $api } from "../AxiosInstance";

export class DefaultSeoService {
  static getAll() {
    return $api.get("/api/admin/settings/seo/default");
  }
  static update(values) {
    return $api.put("/api/admin/settings/seo/default", values);
  }
}
