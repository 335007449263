import {$api} from "../AxiosInstance";

export class PackageProxyCountryService {
    static getPackageById(id) {
        return $api.get(`/api/admin/packageProxy?id=${id}`);
    }
    static savePackage(params) {
        return $api.post(`/api/admin/packageProxy`, params);
    }
    static deletePackageById(id) {
        return $api.delete(`/api/admin/packageProxy?id=${id}`);
    }
    static getAllByProxyTypeId(params) {
        return $api.post(`/api/admin/packageProxy/all/proxyTypeId`, params);
    }
}
