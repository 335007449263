import { $api } from "services/AxiosInstance";

export class ProxyAuthenticationService {
  static getAllProxyAuthentications() {
    return $api.get("/api/admin/proxyAuthentication/all");
  }

  static getProxyAuthentications(page, size) {
    return $api.post("/api/admin/proxyAuthentication/all", { page, size });
  }

  static getProxyAuthentication(id) {
    return $api.get("/api/admin/proxyAuthentication", { params: { id } });
  }

  static createProxyAuthentication(values) {
    return $api.post("/api/admin/proxyAuthentication", values);
  }

  static updateProxyAuthentication(values) {
    return $api.put("/api/admin/proxyAuthentication", values);
  }

  static deleteProxyAuthentication(id) {
    return $api.delete("/api/admin/proxyAuthentication", { params: { id } });
  }
}
